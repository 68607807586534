import {FC, useEffect, useState} from 'react';
import {signInWithRedirect} from 'aws-amplify/auth';
import AppleIcon from '@material-ui/icons/Apple';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {Button, Divider} from '@material-ui/core';
import authStyles from './authStyles';
import {ampli} from '../../ampli';

type Props = {
  prefix?: string;
};
export const SocialAuth = ({prefix = 'Log In'}: Props) => {
  const classes: any = authStyles();
  return (
    <Box
      mb={2}
      mt={2}
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Divider light />
      <Box mt={3}>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          aria-label="Login with Google"
          onClick={async () => {
            try {
              await signInWithRedirect({provider: 'Google'});
              ampli.signupSuccess({type: 'google'});
            } catch (err) {
              console.log('err', err);
              ampli.signupError({message: err.message});
            }
          }}
          className={classes.outlinedButton}>
          <img
            src="/assets/images/social/google-icon.svg"
            style={{height: '16px', width: '16px'}}
          />{' '}
          {prefix} with Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          aria-label="Login with Apple"
          onClick={async () => {
            try {
              await signInWithRedirect({provider: 'Apple'});
              ampli.signupSuccess({type: 'apple'});
            } catch (err) {
              console.log('err', err);
              ampli.signupError({message: err.message});
            }
          }}
          className={classes.outlinedButton}>
          <AppleIcon
            style={{color: 'black', fontSize: '20px'}}
            fontSize="inherit"
          />{' '}
          {prefix} with Apple
        </Button>
      </Box>
    </Box>
  );
};
